import {uploadDocument, uploadImage, uploadVideo} from "../mediaUploader";


export async function handlePostResource(params) {
  const data = params.data;
  if (typeof params.data.image !== 'undefined' && params.data.image !== null) {
    if (params.data.image.rawFile instanceof File) {
      data.image = (await uploadImage(params.data.image, true, data.project))['@id'];
    } else {
      data.image = params.data.image['@id'];
    }
  }
  if (typeof params.data.video !== 'undefined' && params.data.video !== null) {
    if (params.data.video.rawFile instanceof File) {
      data.video = (await uploadVideo(params.data.video, data.project))['@id'];
    } else if (typeof params.data.video === 'object') {
      data.video = params.data.video['@id'];
    }
  }
  if (typeof params.data.downloadableDocument !== 'undefined' && params.data.downloadableDocument !== null) {
    if (params.data.downloadableDocument.rawFile instanceof File) {
      data.downloadableDocument = (await uploadDocument(params.data.downloadableDocument, false, data.project))['@id'];
    } else if (typeof params.data.downloadableDocument === 'object') {
      data.downloadableDocument = params.data.downloadableDocument['@id'];
    }
  }
  return data;
}
