import {uploadImage, uploadVideo} from "../mediaUploader";

export async function handleComparatorConfigResource(params) {
  let data = params.data;
  if (params.data.backgroundImage) {
    if (params.data.backgroundImage.rawFile instanceof File) {
      data.backgroundImage = (await uploadImage(params.data.backgroundImage, false, data.project))['@id'];
    } else {
      data.backgroundImage = params.data.backgroundImage['@id'];
    }
  }
  if (params.data.comparableItemBackgroundImage) {
    if (params.data.comparableItemBackgroundImage.rawFile instanceof File) {
      data.comparableItemBackgroundImage = (await uploadImage(params.data.comparableItemBackgroundImage, false, data.project))['@id'];
    } else {
      data.comparableItemBackgroundImage = params.data.comparableItemBackgroundImage['@id'];
    }
  }
  if (params.data.comparableItemSingleBackgroundImage) {
    if (params.data.comparableItemSingleBackgroundImage.rawFile instanceof File) {
      data.comparableItemSingleBackgroundImage = (await uploadImage(params.data.comparableItemSingleBackgroundImage, false, data.project))['@id'];
    } else {
      data.comparableItemSingleBackgroundImage = params.data.comparableItemSingleBackgroundImage['@id'];
    }
  }
  if (params.data.scanIcon) {
    if (params.data.scanIcon.rawFile instanceof File) {
      data.scanIcon = (await uploadImage(params.data.scanIcon, false, data.project))['@id'];
    } else {
      data.scanIcon = params.data.scanIcon['@id'];
    }
  }
  if (params.data.ocarzChatbotButtonBackground) {
    if (params.data.ocarzChatbotButtonBackground.rawFile instanceof File) {
      data.ocarzChatbotButtonBackground = (await uploadImage(params.data.ocarzChatbotButtonBackground, false, data.project))['@id'];
    } else {
      data.ocarzChatbotButtonBackground = params.data.ocarzChatbotButtonBackground['@id'];
    }
  }
  if (params.data.mainVideo) {
    if (params.data.mainVideo.rawFile instanceof File) {
      data.mainVideo = (await uploadVideo(params.data.mainVideo, data.project))['@id'];
    } else {
      data.mainVideo = params.data.mainVideo['@id'];
    }
  }
  if (typeof params.data.items !== 'undefined' && params.data.items !== null) {
    data.items = params.data.items.map(d => d['@id'])
  }
  return data;
}

export async function handleComparableItemResource(params) {
  let data = params.data;
  if (params.data.image && params.data.image.rawFile instanceof File) {
    data.image = (await uploadImage(params.data.image, false, data.project))['@id'];
  }
  if (params.data.backgroundImage && params.data.backgroundImage.rawFile instanceof File) {
    data.backgroundImage = (await uploadImage(params.data.backgroundImage, false, data.project))['@id'];
  }
  if (params.data.singleBackgroundImage && params.data.singleBackgroundImage.rawFile instanceof File) {
    data.singleBackgroundImage = (await uploadImage(params.data.singleBackgroundImage, false, data.project))['@id'];
  }
  if (params.data.video && params.data.video.rawFile instanceof File) {
    data.video = (await uploadVideo(params.data.video))['@id'];
  }
  return data;
}
