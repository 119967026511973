import {uploadImage} from "../mediaUploader";

export async function handleContentWallConfigResource(params) {
  let data = params.data;
  if (params.data.wallBackground && params.data.wallBackground.rawFile instanceof File) {
    data.wallBackground = (await uploadImage(params.data.wallBackground, false, data.project))['@id'];
  }
  if (params.data.wallSideBarBackground && params.data.wallSideBarBackground.rawFile instanceof File) {
    data.wallSideBarBackground = (await uploadImage(params.data.wallSideBarBackground, false, data.project))['@id'];
  }
  if (params.data.scanStateBackground && params.data.scanStateBackground.rawFile instanceof File) {
    data.scanStateBackground = (await uploadImage(params.data.scanStateBackground, false, data.project))['@id'];
  }
  if (params.data.wallScanIcon && params.data.wallScanIcon.rawFile instanceof File) {
    data.wallScanIcon = (await uploadImage(params.data.wallScanIcon, false, data.project))['@id'];
  }
  if (params.data.wallVisitUrlIcon && params.data.wallVisitUrlIcon.rawFile instanceof File) {
    data.wallVisitUrlIcon = (await uploadImage(params.data.wallVisitUrlIcon, false, data.project))['@id'];
  }
  if (params.data.wallRetrieveContentIcon && params.data.wallRetrieveContentIcon.rawFile instanceof File) {
    data.wallRetrieveContentIcon = (await uploadImage(params.data.wallRetrieveContentIcon, false, data.project))['@id'];
  }
  if (params.data.mobileBackground && params.data.mobileBackground.rawFile instanceof File) {
    data.mobileBackground = (await uploadImage(params.data.mobileBackground, false, data.project))['@id'];
  }
  if (params.data.mobileLogo && params.data.mobileLogo.rawFile instanceof File) {
    data.mobileLogo = (await uploadImage(params.data.mobileLogo, false, data.project))['@id'];
  }
  if (params.data.mobileHeaderBackground && params.data.mobileHeaderBackground.rawFile instanceof File) {
    data.mobileHeaderBackground = (await uploadImage(params.data.mobileHeaderBackground, false, data.project))['@id'];
  }
  if (params.data.mobileFooterBackground && params.data.mobileFooterBackground.rawFile instanceof File) {
    data.mobileFooterBackground = (await uploadImage(params.data.mobileFooterBackground, false, data.project))['@id'];
  }
  if (params.data.mobileRetrieveIcon && params.data.mobileRetrieveIcon.rawFile instanceof File) {
    data.mobileRetrieveIcon = (await uploadImage(params.data.mobileRetrieveIcon, false, data.project))['@id'];
  }
  if (params.data.stateBackground && params.data.stateBackground.rawFile instanceof File) {
    data.stateBackground = (await uploadImage(params.data.stateBackground, false, data.project))['@id'];
  }
  return data;
}
