import {uploadImage, uploadDocument, uploadVideo} from "../mediaUploader";

export async function handleShowyAiConfigResource(params) {
  let data = params.data;
  console.log({config: params.data});
  if (params.data.avatar && params.data.avatar.rawFile instanceof File) {
    data.avatar = (await uploadImage(params.data.avatar, true, data.project))['@id'];
  }
  if (params.data.mainBackground && params.data.mainBackground.rawFile instanceof File) {
    data.mainBackground = (await uploadImage(params.data.mainBackground, true, data.project))['@id'];
  }
  if (params.data.waitingScreenVideo && params.data.waitingScreenVideo.rawFile instanceof File) {
    data.waitingScreenVideo = (await uploadVideo(params.data.waitingScreenVideo, data.project))['@id'];
  }
  if (params.data.speechKeywordTriggerModel && params.data.speechKeywordTriggerModel.rawFile instanceof File) {
    data.speechKeywordTriggerModel = (await uploadDocument(params.data.speechKeywordTriggerModel, data.project))["@id"];
  }
  if (params.data.speechKeywordTriggerMetadata && params.data.speechKeywordTriggerMetadata.rawFile instanceof File) {
    data.speechKeywordTriggerMetadata = (await uploadDocument(params.data.speechKeywordTriggerMetadata, data.project))["@id"];
  }
  if (params.data.porcupineModel && params.data.porcupineModel.rawFile instanceof File) {
    data.porcupineModel = (await uploadDocument(params.data.porcupineModel, data.project))["@id"];
  }
  if (Array.isArray(params.data.speechKeywordTriggerWeights)) {
    const weights = [];
    for (const w of params.data.speechKeywordTriggerWeights) {
      if (typeof w === 'object' && w.rawFile instanceof File) {
        weights.push((await uploadDocument(w, true, data.project))["@id"]);
      } else {
        weights.push(w);
      }
    }
    data.speechKeywordTriggerWeights = weights;
  }
  if (params.data.temperature) {
    data.temperature = parseFloat(params.data.temperature);
  }
  return data;
}
