import {uploadDocument, uploadImage} from "../mediaUploader";

export async function handleProductResource(params) {
  let data = params.data;
  delete data.colors;
  delete data.variants;
  if (params.data.photo && params.data.photo.rawFile instanceof File) {
    data.photo = (await uploadImage(params.data.photo, false, data.project))['@id'];
  } else {
    delete data.photo;
  }
  if (params.data.heroImage && params.data.heroImage.rawFile instanceof File) {
    data.heroImage = (await uploadImage(params.data.heroImage, false, data.project))['@id'];
  } else if (typeof params.data.heroImage === 'object' && params.data.heroImage !== null) {
    delete data.heroImage;
  }
  if (params.data.technicalDocument && params.data.technicalDocument.rawFile instanceof File) {
    data.technicalDocument = (await uploadDocument(params.data.technicalDocument, false, data.project))['@id'];
  } else if (typeof params.data.technicalDocument === 'object' && params.data.technicalDocument !== null) {
    delete data.technicalDocument;
  }
  return data;
}
export async function handleCategoryResource(params) {
  let data = params.data;
  delete data.productRanges;
  return data;
}
export async function handleProductVariantResource(params) {
  let data = params.data;
  delete data.optionValues;
  return data;
}

export async function handleProductRangeResource(params) {
  let data = params.data;
  if (params.data.photo && params.data.photo.rawFile instanceof File) {
    data.photo = (await uploadImage(params.data.photo, false, data.project))['@id'];
  } else {
    delete data.photo;
  }
  return data;
}

export async function handleProductVersionResource(params) {
  let data = params.data;
  if (params.data.photo && params.data.photo.rawFile instanceof File) {
    data.photo = (await uploadImage(params.data.photo, false, data.project))['@id'];
  }
  if (params.data.technicalDocument && params.data.technicalDocument.rawFile instanceof File) {
    data.technicalDocument = (await uploadDocument(params.data.technicalDocument, false, data.project))['@id'];
  }
  return data;
}

export async function handleProductColorResource(params) {
  let data = params.data;

  if (params.data.image && params.data.image.rawFile instanceof File) {
    data.image = (await uploadImage(params.data.image, false, data.project))['@id'];
  }

  return data;
}
