import {entrypoint} from "./data/dataProvider";
import {extractIdFromIri} from "./helpers/iri";

export function uploadImage(image, generateThumbnails = false, project = null) {
  const body = new FormData();
  body.append("file", image.rawFile);
  if (project) {
    body.append("project", extractIdFromIri(project));
  }
  const params = generateThumbnails === true ? '?generate-thumbnails' : '';
  return (fetch(`${entrypoint}/images${params}`, {
    body,
    headers: new Headers({
      Authorization: `Bearer ${window.localStorage.getItem("token")}`
    }),
    method: "POST"
  })
    .then(function (response) {
      return response.json();
    })
    .then(function (jsondata) {
      return jsondata;
    }));
}

export function uploadVideo(video, project = null) {
  const body = new FormData();
  body.append("file", video.rawFile);
  if (project) {
    body.append("project", extractIdFromIri(project));
  }
  return (fetch(`${entrypoint}/videos`, {
    body,
    headers: new Headers({
      Authorization: `Bearer ${window.localStorage.getItem("token")}`
    }),
    method: "POST"
  })
    .then(function (response) {
      return response.json();
    })
    .then(function (jsondata) {
      return jsondata;
    }));
}

export function uploadDocument(document, preserveOriginalName = false, project = null) {
  const body = new FormData();
  body.append("file", document.rawFile);
  if (project) {
    body.append("project", extractIdFromIri(project));
  }
  return (fetch(`${entrypoint}/documents${preserveOriginalName===true ? '?preserveOriginalName' : ''}`, {
    body,
    headers: new Headers({
      Authorization: `Bearer ${window.localStorage.getItem("token")}`
    }),
    method: "POST"
  })
    .then(function (response) {
      return response.json();
    })
    .then(function (jsondata) {
      return jsondata;
    }));
}
