import {uploadDocument} from "../mediaUploader";


export async function handleMailConfigResource(params) {
  const data = params.data;
  if (params.data.template && params.data.template.rawFile instanceof File) {
    data.template = (await uploadDocument(params.data.template, data.project))['@id'];
  }
  if (!params.data.postAttachmentTypes) {
    data.postAttachmentTypes = [];
  }
  return data;
}
