import {CreateGuesser, EditGuesser, FieldGuesser, InputGuesser} from "@api-platform/admin";
import React from "react";
import {
  SelectInput,
  required,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  ImageInput,
  Tab,
  ReferenceManyField,
  Datagrid,
  TextField,
  ShowButton,
  EditButton,
  TabbedShowLayout,
  NumberField,
  FileInput
} from "react-admin";
import CustomImageField from "../components/fields/CustomImageField";
import AddComparableItemButton from "../comparator/addComparableItemButton";
import AddAttributeButton from "./addAttributeButton";
import CustomVideoField from "../components/fields/CustomVideoField";
import {RefreshableListBySelectedProject} from "../RefreshableListBySelectedProject";

export const ComparatorConfigList = props => (
  <RefreshableListBySelectedProject {...props}>
    <FieldGuesser source={"name"}/>
    <FieldGuesser source={"maxVisibleItems"}/>
    <FieldGuesser source={"compareBehavior"}/>
    <FieldGuesser source={"displayType"}/>
  </RefreshableListBySelectedProject>
);

export const ComparatorConfigCreate = props => (
  <CreateGuesser {...props}>
    <InputGuesser source={"name"} validate={[required()]}/>
    <InputGuesser source={"title"}/>
    <InputGuesser source={"subtitle"}/>
    <InputGuesser source={"maxVisibleItems"} validate={[required()]}/>
    <SelectInput source="compareBehavior" choices={[
      {id: 'pick_up', name: 'Pick Up'},
      {id: 'drop', name: 'Drop'},
    ]}/>
    <SelectInput source="displayType" choices={[
      {id: 'by_position', name: 'By Position'},
      {id: 'by_order', name: 'By Order'},
    ]}/>
    {/*<ReferenceArrayInput
      source="items"
      reference="comparable_items"
      label="Items"
      filterToQuery={searchText => ({name: searchText})}
    >
      <AutocompleteArrayInput optionText="title"/>
    </ReferenceArrayInput>*/}

    <InputGuesser source={"priceLabel"}/>
    <InputGuesser source={"currency"}/>

    <InputGuesser source={"stickyQrCodeUrl"}/>

    <FileInput source="mainVideo" label="Video" accept="video/*"
             placeholder={<p>Drop your file here</p>}>
      <CustomVideoField source="contentUrl"/>
    </FileInput>

    <InputGuesser source={"backgroundColor"}/>
    <ImageInput source="backgroundImage" label="Background image" accept="image/*"
                placeholder={<p>Drop your file here</p>}>
      <CustomImageField source="contentUrl"/>
    </ImageInput>

    <InputGuesser source={"comparableItemBackgroundColor"}/>
    <ImageInput source="comparableItemBackgroundImage" label="comparable Item Background Color" accept="image/*"
                placeholder={<p>Drop your file here</p>}>
      <CustomImageField source="contentUrl"/>
    </ImageInput>

    <InputGuesser source={"comparableItemSingleBackgroundColor"}/>
    <ImageInput source="comparableItemSingleBackgroundImage" label="comparable Item Single Background Image"
                accept="image/*"
                placeholder={<p>Drop your file here</p>}>
      <CustomImageField source="contentUrl"/>
    </ImageInput>

    <InputGuesser source={"scanMessage"}/>
    <ImageInput source="scanIcon" label="Scan icon" accept="image/*"
                placeholder={<p>Drop your file here</p>}>
      <CustomImageField source="contentUrl"/>
    </ImageInput>

    <InputGuesser source={"ocarzChatbotUrl"}/>
    <ImageInput source="ocarzChatbotButtonBackground" label="Ocarz Chatbot Button Background" accept="image/*"
                placeholder={<p>Drop your file here</p>}>
        <CustomImageField source="contentUrl"/>
    </ImageInput>

    {/*<InputGuesser source={"apiUrl"}/>*/}
    {/*<InputGuesser source={"apiUrlAuthorizationHeaderValue"}/>*/}

    <InputGuesser source={"cssStyle"} multiline fullWidth={true}/>

    <InputGuesser source={"javascripts"} multiline fullWidth={true}/>

    <ReferenceArrayInput
      source="devices"
      reference="devices"
      label="Devices"
      filterToQuery={searchText => ({name: searchText})}
      filter={{ 'application.slug': 'comparator'}}
    >
      <AutocompleteArrayInput optionText="name"/>
    </ReferenceArrayInput>
  </CreateGuesser>
);

export const ComparatorConfigEdit = props => (
  <EditGuesser {...props}>
    <TabbedShowLayout fullWidth={true}>
      <Tab label="Summary">
        <InputGuesser source={"name"} validate={[required()]}/>
        <InputGuesser source={"title"}/>
        <InputGuesser source={"subtitle"}/>
        <InputGuesser source={"maxVisibleItems"} validate={[required()]}/>
        <SelectInput source="compareBehavior" choices={[
          {id: 'pick_up', name: 'Pick Up'},
          {id: 'drop', name: 'Drop'},
        ]}/>
        <SelectInput source="displayType" choices={[
          {id: 'by_position', name: 'By Position'},
          {id: 'by_order', name: 'By Order'},
        ]}/>

        <InputGuesser source={"priceLabel"}/>
        <InputGuesser source={"currency"}/>

        <InputGuesser source={"stickyQrCodeUrl"}/>

        <FileInput source="mainVideo" label="Video" accept="video/*"
            placeholder={<p>Drop your file here</p>}>
            <CustomVideoField source="contentUrl"/>
        </FileInput>

        <InputGuesser source={"backgroundColor"}/>
        <ImageInput source="backgroundImage" label="Background image" accept="image/*"
                    placeholder={<p>Drop your file here</p>}>
          <CustomImageField source="contentUrl"/>
        </ImageInput>

        <InputGuesser source={"comparableItemBackgroundColor"}/>
        <ImageInput source="comparableItemBackgroundImage" label="comparable Item Background Color" accept="image/*"
                    placeholder={<p>Drop your file here</p>}>
          <CustomImageField source="contentUrl"/>
        </ImageInput>

        <InputGuesser source={"comparableItemSingleBackgroundColor"}/>
        <ImageInput source="comparableItemSingleBackgroundImage" label="comparable Item Single Background Image"
                    accept="image/*"
                    placeholder={<p>Drop your file here</p>}>
          <CustomImageField source="contentUrl"/>
        </ImageInput>

        <InputGuesser source={"scanMessage"}/>
        <ImageInput source="scanIcon" label="Scan icon" accept="image/*"
                    placeholder={<p>Drop your file here</p>}>
          <CustomImageField source="contentUrl"/>
        </ImageInput>

        <InputGuesser source={"ocarzChatbotUrl"}/>
        <ImageInput source="ocarzChatbotButtonBackground" label="Ocarz Chatbot Button Background" accept="image/*"
                    placeholder={<p>Drop your file here</p>}>
          <CustomImageField source="contentUrl"/>
        </ImageInput>

        {/*<InputGuesser source={"apiUrl"}/>*/}
        {/*<InputGuesser source={"apiUrlAuthorizationHeaderValue"}/>*/}

        <InputGuesser source={"cssStyle"} multiline fullWidth={true}/>

        <InputGuesser source={"javascripts"} multiline fullWidth={true}/>

        <ReferenceArrayInput
          source="devices"
          reference="devices"
          label="Devices"
          filterToQuery={searchText => ({name: searchText})}
          filter={{ 'application.slug': 'comparator'}}
        >
          <AutocompleteArrayInput optionText="name"/>
        </ReferenceArrayInput>
      </Tab>
      <Tab label="Attributes" path="attributes">
        <ReferenceManyField
          addLabel={false}
          reference="attributes"
          target="comparatorConfig"
          sort={{field: 'position', order: 'ASC'}}>
          <Datagrid>
            <TextField source="name"/>
            <TextField source="type"/>
            <TextField source="uid"/>
            <NumberField source="position"/>
            <ShowButton/>
            <EditButton/>
          </Datagrid>
        </ReferenceManyField>
        <AddAttributeButton/>
      </Tab>
      <Tab label="Comparable Items" path="items">
        <ReferenceManyField
          addLabel={false}
          reference="comparable_items"
          filter={{ 'itemsPerPage': 1000 }}
          target="comparatorConfig">
          <Datagrid>
            <TextField source="title"/>
            <TextField source="subtitle"/>
            <TextField source="uid"/>
            <NumberField source="position"/>
            <NumberField source="price"/>
            <ShowButton/>
            <EditButton/>
          </Datagrid>
        </ReferenceManyField>
        <AddComparableItemButton/>
      </Tab>
    </TabbedShowLayout>
  </EditGuesser>
);
